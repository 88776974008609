import { useEffect, useRef, useState } from "react";
import "./LightShowMobileWeb.css";
import { socket } from "../../socket";
import { detectionThreshold } from "../LightShow/Common";
import generateUserid from "../../utils/generateUserid";
import Logo from "./Logo";

import {
  getItemFromSessionStorage,
  saveItemToSessionStorage,
} from "../../utils/utility";
import { storageKeyForLightShowBrightness } from "../../utils/defaultSettings";
import { CDN_URLS } from "../../constants";
import { AD_TYPES } from "../AdsSetupManager/functions";
import LightShowEffects from "./LightShowEffects";
import { getStreamingLightShow } from "../Games/networkCalls/networkCalls";
const beatThreshold = 15;
const BRIGHTNESS_MSG_DURATION = 10000; // 10seconds
const LightShowMobileWeb = ({ userId, eventData, advertsData }) => {
  const [showBrightnessMessage, setShowBrightnessMessage] = useState(false);
  const getPostEventAdData = () => {
    let data = null;
    if (advertsData && advertsData.length) {
      const postEventAd = advertsData.find(
        (ad) => ad?.adType === AD_TYPES.LIGHT_SHOW
      );
      if (postEventAd) {
        data = postEventAd;
      }
      return data;
    }
  };
  const [postEventAd, setPostEventAd] = useState(getPostEventAdData);
  const [showAd, setShowAd] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [show, setShow] = useState(null);

  const [pulse, setPulse] = useState(false);

  const logErrors = () => {
    socket.on("connect_error", (err) => {
      // the reason of the error, for example "xhr poll error"
      console.log(err.message);

      // some additional description, for example the status code of the initial HTTP response
      console.log(err.description);

      // some additional context, for example the XMLHttpRequest object
      console.log(err.context);
    });
  };

  let userID = useRef(userId);

  const triggerBrightnessMessage = () => {
    const brightnessToggle = getItemFromSessionStorage(
      storageKeyForLightShowBrightness
    );
    if (!brightnessToggle) {
      setShowBrightnessMessage(true);
      saveItemToSessionStorage(storageKeyForLightShowBrightness, true);
      setTimeout(() => {
        setShowBrightnessMessage(false);
      }, BRIGHTNESS_MSG_DURATION);
    }
  };
  const onDisconnect = () => {
    setPulse(false);
    setShow(null);

    setShowLogo(false);
    setShowAd(true);
  };
  const onDataArray = ({ triggerDisconnect, dataArray, settings, eventId }) => {
    if (triggerDisconnect) {
      onDisconnect();
      return;
    }
    if (eventId !== eventData._id) {
      return;
    }

    if (dataArray !== null && dataArray !== undefined && settings) {
      const intensity = dataArray;
      const threshold =
        settings.targetFrequency === 0 ? beatThreshold - 1 : detectionThreshold;

      const show = settings;

      // Play on audience phone screen
      setPulse(intensity > threshold);
      setShow(show);

      setShowAd(false);
      setShowLogo(true);
    }
  };

  const getAlreadyStreamingShow = async () => {
    if (eventData?._id) {
      const response = await getStreamingLightShow(eventData._id);
      if (response) {
        if (
          response?.isStreaming &&
          response?.emitData &&
          Object.keys(response.emitData).length
        ) {
          onDataArray(response.emitData);
        }
      }
    }
  };

  useEffect(() => {
    getAlreadyStreamingShow();
    triggerBrightnessMessage();
    if (userID.current === "" || userID.current === null) {
      const databaseUniqueUserID = localStorage.getItem("databaseUniqueUserID");
      if (databaseUniqueUserID) {
        userID.current = databaseUniqueUserID;
      } else {
        userID.current = generateUserid();
      }
    }

    socket.connect();
    socket.on("dataArray", onDataArray);
    socket.on("disconnect", onDisconnect);

    logErrors();
    return () => {
      socket.disconnect();
      socket.off("dataArray", onDataArray);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  return (
    <div className="light-show-web-app-container">
      <div className="light-show-preview-web-app">
        {pulse && show ? <LightShowEffects {...show} /> : null}
      </div>
      {showBrightnessMessage ? (
        <video
          src={CDN_URLS["BRIGHTNESS"]}
          playsInline
          autoPlay
          loop
          muted
          className="brightnessMessageContainer"
        />
      ) : null}
      {showLogo &&
      !showBrightnessMessage &&
      show &&
      show.mediaForAudienceScreen ? (
        <Logo {...show} />
      ) : null}

      {postEventAd && showAd ? (
        <div className="lsAdContainer">
          {/* <h2 className="lsThank">Thank you for joining the light show!</h2> */}
          {postEventAd.mimeType.includes("video") ? (
            <video
              src={postEventAd.adMediaUrl}
              autoPlay
              loop
              muted
              className="lsAdMedia"
            />
          ) : (
            <img src={postEventAd.adMediaUrl} className="lsAdMedia" alt="Ad" />
          )}
        </div>
      ) : null}
    </div>
  );
};
export default LightShowMobileWeb;
