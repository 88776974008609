import { socket } from "../../socket";
import { useEffect, useState } from "react";
import { ToggleButton } from "@mui/material";
import { Circle } from "@mui/icons-material";
const ConnectionManager = ({ onChange }) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const connect = () => {
    socket.connect();
  };

  const disconnect = () => {
    socket.disconnect();
  };
  const onConnect = () => {
    setIsConnected(true);
  };

  const onDisconnect = () => {
    setIsConnected(false);
  };

  const logConnectionErros = (err) => {
    // the reason of the error, for example "xhr poll error"
    console.log(err.message);
    // some additional description, for example the status code of the initial HTTP response
    console.log(err.description);
    // some additional context, for example the XMLHttpRequest object
    console.log(err.context);
  };
  const onBeforeUnload = () => {
    socket.emit("data", {
      triggerDisconnect: true,
    });
  };

  useEffect(() => {
    socket.on("connect_error", logConnectionErros);
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      socket.emit("data", {
        triggerDisconnect: true,
      });
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", logConnectionErros);
      window.removeEventListener("beforeunload", onBeforeUnload);
      disconnect();
    };
  }, []);
  useEffect(() => {
    onChange(isConnected);
  }, [isConnected]);
  const handleChange = (event) => {
    let timeoutId;
    setIsConnected((pre) => {
      const value = !pre;
      if (value) {
        connect();
      } else {
        socket.emit("data", {
          triggerDisconnect: true,
        });
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          disconnect();
        }, 100);
      }
      value ? connect() : disconnect();
      return value;
    });
  };
  return (
    <ToggleButton
      value="check"
      selected={isConnected}
      color="success"
      onChange={handleChange}
      className="socket-connection-toggle"
      title={isConnected ? "Streaming to Audience" : "Start Streaming"}
    >
      {isConnected ? <Circle className="blink" /> : <Circle />}
    </ToggleButton>
  );
};
export default ConnectionManager;
