import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
  OutlinedInput,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { useState } from "react";
import { targetFrequencies, effects } from "./Common";
import { updateLightShow } from "../Games/networkCalls/networkCalls";
const LightShowDetails = ({ show, onUpdate }) => {
  const [title, setTitle] = useState(show.title);
  const [targetFrequency, setTargetFrequency] = useState(show.targetFrequency);
  const [effect, setEffect] = useState(show.effect);
  const [color, setColor] = useState(show.color);
  const [color2, setColor2] = useState(show.color2);
  const [color1HoldTime, setColor1HoldTime] = useState(show.color1HoldTime);
  const [color2HoldTime, setColor2HoldTime] = useState(show.color2HoldTime);
  const [animationDuration, setAnimationDuration] = useState(
    show.animationDuration
  );

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = async () => {
    await updateLightShow({
      showId: show._id,
      title,
      isActive: true,
      targetFrequency,
      color,
      effect,
      color2,
      color1HoldTime,
      color2HoldTime,
      animationDuration,
    });
    onUpdate();
  };
  return (
    <div className="light-show-details">
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        value={title}
        onChange={onTitleChange}
        margin="normal"
        sx={{ marginBottom: "16px" }}
        InputProps={{
          className: "white-border-control",
        }}
      />
      <FormControl fullWidth sx={{ marginBottom: "16px" }}>
        <InputLabel>Target Frequency</InputLabel>
        <Select
          variant="outlined"
          value={targetFrequency}
          onChange={(e) => {
            setTargetFrequency(e.target.value);
          }}
          label="Target Frequency"
        >
          {targetFrequencies.map(({ name, value }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Effect</InputLabel>
        <Select
          variant="outlined"
          value={effect}
          onChange={(e) => {
            setEffect(e.target.value);
          }}
          label="Effect"
        >
          {effects.map(({ name, value }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {effect !== 4 && (
        <>
          <Grid container columns={12}>
            <Grid item fullWidth xs={12} md={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%", marginTop: "16px" }}
              >
                <MuiColorInput
                  format="hex"
                  value={color}
                  label="Select color 1"
                  onChange={(newColor) => {
                    setColor(newColor);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item fullWidth xs={12} md={6} justifyItems="end">
              <FormControl
                sx={{
                  width: "calc(100% - 16px)",
                  marginTop: "16px",
                  marginLeft: "16px",
                }}
              >
                <InputLabel htmlFor="hold1">
                  Set Hold Seconds for Color 1
                </InputLabel>
                <OutlinedInput
                  id="hold1"
                  type="number"
                  inputProps={{ min: 1 }}
                  value={color1HoldTime}
                  label="Set Hold Seconds for Color 1"
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setColor1HoldTime(newValue);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          {effect !== 2 && (
            <Grid container columns={12}>
              <Grid item fullWidth xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  sx={{ width: "100%", marginTop: "16px" }}
                >
                  <MuiColorInput
                    format="hex"
                    value={color2}
                    label="Select color 2"
                    onChange={(newColor) => {
                      setColor2(newColor);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item fullWidth xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  sx={{
                    width: "calc(100% - 16px)",
                    marginTop: "16px",
                    marginLeft: "16px",
                  }}
                >
                  <InputLabel htmlFor="hold2">
                    Set Hold Seconds for Color 2
                  </InputLabel>
                  <OutlinedInput
                    label="Set Hold Seconds for Color 2"
                    id="hold2"
                    type="number"
                    inputProps={{ min: 1 }}
                    value={color2HoldTime}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setColor2HoldTime(newValue);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
        </>
      )}

      <FormControl
        sx={{ width: "100%", marginTop: "16px" }}
        title="Higher the value slower the animation."
      >
        <InputLabel htmlFor="duration">
          Set Animation Duration (Seconds)
        </InputLabel>
        <OutlinedInput
          label="Set Animation Duration Seconds"
          id="duration"
          type="number"
          inputProps={{ min: 1 }}
          value={animationDuration}
          onChange={(e) => {
            const newValue = e.target.value;
            setAnimationDuration(newValue);
          }}
        />
      </FormControl>
      <FormControl variant="standard" sx={{ width: "100%" }}>
        <Button
          variant="contained"
          disableElevation
          sx={{ mt: 2 }}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </FormControl>
    </div>
  );
};
export default LightShowDetails;
