import "./LightShowConsole.css";

import { useEffect, useRef } from "react";
import { socket } from "../../socket";
import { beatThreshold, detectionThreshold } from "./Common";
import { getRandomColor } from "../../utils/utility";
import { updateLightShow } from "../Games/networkCalls/networkCalls";

let audioContext;
let analyser;
let dataArray;
let randomColorSetMilliSeconds = null;
const LOW_PULSE_THRESHOLD = 2;
const HIGH_PULSE_THRESHOLD = 20;

const LightShowConsole = ({
  allShows,
  eventId,
  playEffect,
  settings,
  manualActiveShowId,
  isConnected,
}) => {
  const currentShowsState = useRef({});
  const updateLightShowState = async (showPayload, isStreaming, emitData) => {
    // Update Lightshow
    if (showPayload) {
      const {
        _id,
        title,
        targetFrequency,
        color,
        effect,
        color2,
        color1HoldTime,
        color2HoldTime,
        animationDuration,
      } = showPayload;
      await updateLightShow({
        showId: _id,
        title,
        isActive: true,
        targetFrequency,
        color,
        effect,
        color2,
        color1HoldTime,
        color2HoldTime,
        animationDuration,
        isStreaming: isStreaming,
        emitData,
      });
    } else {
      await updateLightShow({
        eventId,
      });
    }
  };
  const play = (intensity, threshold, show, emitData) => {
    playEffect(intensity, threshold, show);
    socket.emit("data", emitData);
    if (isConnected) {
      updateLightShowState(show, true, emitData);
    } else {
      updateLightShowState(null, false, emitData);
    }
  };
  const stopEffect = (intensity, threshold, show, emitData) => {
    playEffect(intensity, threshold, show);
    socket.emit("data", emitData);
    updateLightShowState(null, false, emitData);
  };
  const checkFrequency = (targetFrequency, _settings) => {
    analyser.getByteFrequencyData(dataArray);
    let intensity;
    let threshold;
    if (targetFrequency === 0) {
      // Live audio case
      threshold = beatThreshold - 1;
      intensity =
        dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
    } else {
      // Tune frequncy case
      threshold = detectionThreshold;
      const binIndex = Math.round(
        targetFrequency / (audioContext.sampleRate / analyser.fftSize)
      );
      intensity = dataArray[binIndex];
    }
    const show = allShows.find((e) => e.targetFrequency === targetFrequency);
    if (show.effect === 4) {
      // Random color effect
      if (
        randomColorSetMilliSeconds === null ||
        Number(Date.now()) - Number(randomColorSetMilliSeconds) >
          show.animationDuration * 1000
      ) {
        randomColorSetMilliSeconds = Date.now();
        show.color = getRandomColor();
      }
    }

    if (intensity > threshold) {
      if (!currentShowsState.current[show._id]?.isPlaying) {
        if (currentShowsState.current[show._id]?.highPulseCount) {
          if (
            currentShowsState?.current[show._id].highPulseCount.length >
            HIGH_PULSE_THRESHOLD
          ) {
            // START SHOW
            const emitData = {
              dataArray: intensity,
              settings: { ...show, ..._settings },
              eventId,
            };
            play(intensity, threshold, show, emitData);

            currentShowsState.current = {
              [show._id]: {
                isPlaying: true,
                lowPulseCount: [],
                highPulseCount: [],
              },
            };
          } else {
            currentShowsState.current[show._id].highPulseCount.push(
              show.targetFrequency
            );
          }
        } else {
          if (currentShowsState.current[show._id]) {
            currentShowsState.current[show._id].highPulseCount = [
              show.targetFrequency,
            ];
          } else {
            currentShowsState.current[show._id] = {
              isPlaying: false,
              lowPulseCount: [],
              highPulseCount: [],
            };
          }
        }
      }
    } else {
      if (currentShowsState?.current[show._id]) {
        if (
          currentShowsState?.current[show._id].lowPulseCount.length >
          LOW_PULSE_THRESHOLD
        ) {
          // STOP SHOW
          const emitData = {
            dataArray: 0,
            settings: { ...show, ..._settings },
            eventId,
          };
          stopEffect(0, threshold, show, emitData);
          currentShowsState.current = {};
        } else {
          currentShowsState.current[show._id].lowPulseCount.push(intensity);
        }
      }
    }
  };
  const intervaIds = [];

  const startAudioDetection = async () => {
    /**
     * START:: MANUAL PLAY STOP
     */

    if (manualActiveShowId && manualActiveShowId !== 0) {
      // Play manually
      const show = allShows.find((e) => e._id === manualActiveShowId);
      if (show) {
        const threshold =
          show.targetFrequency === 0 ? beatThreshold - 1 : detectionThreshold;
        const falseIntensity =
          Math.floor(Math.random() * (255 - threshold)) + threshold;

        const emitData = {
          dataArray: falseIntensity,
          settings: { ...show, ...settings },
          eventId,
        };
        play(falseIntensity, threshold, show, emitData);
      }
      return;
    } 
    else {
      // Stop manual
      const emitData = {
        triggerDisconnect: true,
        dataArray: 0,
        settings: null,
        eventId,
      };
      stopEffect(0, beatThreshold, null, emitData);
    }
    /**
     * END:: MANUAL PLAY STOP
     */
    try {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 8192;
      dataArray = new Uint8Array(analyser.frequencyBinCount);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioSource = audioContext.createMediaStreamSource(stream);
      const mediaStreamDestination =
        audioContext.createMediaStreamDestination();
      audioSource.connect(analyser);
      analyser.connect(mediaStreamDestination);
      mediaStreamDestination.stream.getAudioTracks().forEach((track) => {
        audioContext.suspend();
        audioContext.resume();
        track.stop();
      });
      allShows.forEach((show) => {
        const intervalMilliSeconds = show.targetFrequency === 0 ? 25 : 10;
        const checkInterval = setInterval(() => {
          checkFrequency(show.targetFrequency, settings);
        }, intervalMilliSeconds);
        intervaIds.push(checkInterval);
      });
    } catch (e) {
      alert("Web Audio API is not supported in this browser");
    }
  };
  const stopAudioDetection = () => {
    audioContext?.close();
  };
  const resetManualDetection = () => {
    const emitData = {
      triggerDisconnect: true,
      dataArray: 0,
      settings: null,
      eventId,
    };
    stopEffect(0, beatThreshold, null, emitData);
  };
  useEffect(() => {
    startAudioDetection();
    return () => {
      stopAudioDetection();
      resetManualDetection();
    };
  }, []);

  const stop = () => {
    intervaIds.forEach(clearInterval);
  };
  useEffect(() => {
    if (allShows.length) {
      startAudioDetection();
    }

    return () => {
      stop();
    };
  }, [allShows, manualActiveShowId, isConnected]);
  return (
    <div style={{ width: "0px", height: "0px", visibility: "hidden" }}></div>
  );
};
export default LightShowConsole;
