export const BASE_URL = "https://dev-apiuvenu.uvenu.com";
// export const BASE_URL = "https://dev-apiuvenu.uvenu.com";
export const URLS = {
  SERVER_CONFIG_URL: `${BASE_URL}/config`,
  MONITOR_ONLINE_USERS: (eventId, sourceName) =>
    `${BASE_URL}/onlinePresence/monitorOnlineUsers?eventId=${eventId}&sourceName=${encodeURIComponent(
      sourceName
    )}`,
  GET_ONLINE_USERS: (eventId, sourceName, lastEventUserId, numUsers = "9") => {
    let url = `${BASE_URL}/onlinePresence/getOnlineUsers?eventId=${eventId}&sourceName=${encodeURIComponent(
      sourceName
    )}`;
    if (lastEventUserId) {
      url += `&lastEventUserId=${encodeURIComponent(lastEventUserId)}`;
    }
    if (numUsers) {
      url += `&numUsers=${encodeURIComponent(numUsers)}`;
    }
    return url;
  },
  ADMIN_LOGIN: `${BASE_URL}/admin/login`,
  GET_ALL_EVENTS: `${BASE_URL}/admin/getAllEvents`,
  CREATE_EVENT: `${BASE_URL}/admin/createEvent`,
  EVENT_INFO: (eventId) =>
    `${BASE_URL}/event/getEvent?eventId=${encodeURIComponent(eventId)}`,
  EVENT_SETTINGS: (eventId) =>
    `${BASE_URL}/event/${encodeURIComponent(eventId)}/output-settings`,
  EVENT_USERS_COUNTS: (eventId) =>
    `${BASE_URL}/admin/eventUserCounts?eventId=${encodeURIComponent(eventId)}`,
  EVENT_SCREEN_LINK: (eventId) =>
    `${window.location.origin}/eventScreen#${eventId}`,
  EVENT_JOIN_PAGE_LIVE: (eventId, options, page) =>
    `${window.location.origin}/joinEventLive#eventId=${eventId}&loadwithoptions=${options}&page=${page}`,
  EVENT_SCREEN_COMMAND_SUSCRIBE_LINK: (eventId, token) =>
    `${BASE_URL}/eventsScreen/subscribe?eventId=${eventId}&token=${token}`,
  EVENT_SCREEN_COMMAND_PUBLISH_LINK: `${BASE_URL}/eventsScreen/publish`,
  DELETE_EVENT: `${BASE_URL}/admin/deleteEvent`,
  EDIT_EVENT: `${BASE_URL}/admin/updateEvent`,
  UPLOAD_ADVERTISEMENT_BANNER: `${BASE_URL}/advertisement/upload`,
  UPDATE_ADVERTISEMENT_BANNER: `${BASE_URL}/advertisement/update`,
  ONLINE_PRESENCE: (userId, userName, eventId, uniqueId, sourceName) =>
    `${BASE_URL}/onlinePresence/online?userId=${encodeURIComponent(
      userId
    )}&userName=${encodeURIComponent(userName)}&eventId=${encodeURIComponent(
      eventId
    )}&uniqueId=${encodeURIComponent(uniqueId)}&sourceName=${encodeURIComponent(
      sourceName
    )}`,
  GET_ADVERTS_FOR_EVENT: (eventId) =>
    `${BASE_URL}/advertisement/getAdvertsForEvent?eventId=${eventId}`,
  GET_NEARBY_EVENT: (latitude, longitude) =>
    `${BASE_URL}/event/getNearbyEvents?coordinates=${latitude},${longitude}`,
  GET_ALL_GAME_DATA: (event_id) =>
    `${BASE_URL}/game/getGamesForEvent?eventId=${event_id}`,
  CREATE_GAME: `${BASE_URL}/game/create`,
  PATCH_GAME: `${BASE_URL}/game/update`,
  GET_ACTIVE_GAME: (event_id, user_id) =>
    `${BASE_URL}/game/getActiveGameForEvent?eventId=${event_id}&userId=${user_id}`,
  DELETE_GAME: (game_id) => `${BASE_URL}/game/delete?gameId=${game_id}`,
  DELETE_GAME_ANSWERS: (game_id) =>
    `${BASE_URL}/game/deleteGameAnswers?gameId=${game_id}`,
  SUBMIT_GAME_ANSWER: `${BASE_URL}/game/submitAnswer`,
  GAME_SCREEN_LINK: (eventId, gameId) =>
    `${window.location.origin}/gameScreen#event=${eventId}&game=${gameId}`,
  GET_GAME_STATS: (gameId) =>
    `${BASE_URL}/game/getGameResults?gameId=${gameId}`,
  GET_GAME_SCREEN_SETTINGS: (gameId) =>
    `${BASE_URL}/game/getGameResultsScreenSettings?gameId=${gameId}`,
  SAVE_LIGHT_SHOW_SETTINGS: `${BASE_URL}/lightShow/saveSettings`,
  GET_LIGHT_SHOW_SETTINGS: (event_id) =>
    `${BASE_URL}/lightShow/getLightShowsSettingsForEvent?eventId=${event_id}`,
  GET_STREAMING_LIGHT_SHOW: (event_id) =>
    `${BASE_URL}/lightShow/getStreamingShow?eventId=${event_id}`,
  CREATE_LIGHT_SHOW: `${BASE_URL}/lightShow/create`,
  PATCH_LIGHT_SHOW: `${BASE_URL}/lightShow/update`,
  DELETE_LIGHT_SHOW: (showId) =>
    `${BASE_URL}/lightShow/delete?showId=${showId}`,
  GET_LIGHT_SHOWS: (event_id) =>
    `${BASE_URL}/lightShow/getLightShowsForEvent?eventId=${event_id}`,
  UPLOAD_MEDIA: () => `${BASE_URL}/sharedMedia/upload`,
  UPLOAD_VIDEO_POSTER: () => `${BASE_URL}/sharedMedia/uploadVideoPoster`,
  SEND_SOCIAL_LIKE: () => `${BASE_URL}/sharedMedia/like`,
  GET_UNREVIEWED_MEDIA: (
    eventId,
    page,
    pageSize,
    mimeType,
    sortDirection,
    userNameContains
  ) => {
    let url = `${BASE_URL}/sharedMedia/unreviewed?eventId=${eventId}&page=${page}&pageSize=${pageSize}`;
    if (mimeType) url += `&mimeType=${mimeType}`;
    if (sortDirection) url += `&sortDirection=${sortDirection}`;
    if (userNameContains)
      url += `&userNameContains=${encodeURIComponent(userNameContains)}`;
    return url;
  },
  REVIEW_MEDIA: `${BASE_URL}/sharedMedia/review`,
  PURGE_ALL_MEDIA: `${BASE_URL}/sharedMedia/purgeMany`,
  GET_SOCIAL_SCROLL: (
    eventId,
    page,
    pageSize,
    mimeType,
    showOnQuotesFeed,
    sortDirection,
    userNameContains
  ) => {
    let url = `${BASE_URL}/sharedMedia/scroll?eventId=${eventId}&page=${page}&pageSize=${pageSize}`;
    if (showOnQuotesFeed) url += `&showOnQuotesFeed=${showOnQuotesFeed}`;
    if (mimeType) url += `&mimeType=${mimeType}`;
    if (sortDirection) url += `&sortDirection=${sortDirection}`;
    if (userNameContains)
      url += `&userNameContains=${encodeURIComponent(userNameContains)}`;
    return url;
  },
  SOCIAL_OP_SCREEN_LINK: (eventId) =>
    `${window.location.origin}/Social#${eventId}`,
  SUPER_SCREEN_LINK: `${window.location.origin}/SuperScreen`,
  SOCIAL_OP_CAROUSEL_SCREEN_LINK: (eventId) =>
    `${window.location.origin}/SocialCarousel#${eventId}`,
  SOCIAL_OP_SLIDESHOW_SCREEN_LINK: (eventId) =>
    `${window.location.origin}/SocialSlideshow#${eventId}`,
  SOCIAL_OUTPUT_LINK: (eventId) =>
    `${window.location.origin}/social-output/${eventId}`,
  SOCIAL_OP_DYNAMIC_MASONARY_SCREEN_LINK: (eventId) =>
    `${window.location.origin}/SocialDynamicMasonry#${eventId}`,
  SOCIAL_OP_FIXED_MASONARY_SCREEN_LINK: (eventId) =>
    `${window.location.origin}/SocialFixedMasonry#${eventId}`,
  SOCIAL_MEDIA_LIKE: `${BASE_URL}/sharedMedia/like`,
  SUBMIT_USERS_EMAILS: (eventId) => `${BASE_URL}/event/${eventId}/contacts`,
  CREATE_SUPER_SCREEN_LAYOUT: `${BASE_URL}/superScreen/create`,
  GET_SUPER_SCREEN_LAYOUTS: (event_id) =>
    `${BASE_URL}/superScreen/getSuperScreenLayouts?eventId=${event_id}`,
  DELETE_SUPER_SCREEN_LAYOUT: (layoutId) =>
    `${BASE_URL}/superScreen/delete?layoutId=${layoutId}`,
  PATCH_SUPER_SCREEN_LAYOUT: `${BASE_URL}/superScreen/update`,
};

export let SIGNALING_SERVER_URL = "";
export function updateSignallingUrl(signalingUrl) {
  SIGNALING_SERVER_URL = signalingUrl;
}

export let ICE_SERVERS = [];
export function updateICEServers(iceServers) {
  ICE_SERVERS = iceServers;
}

let CDN_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:10000/devstoreaccount1/public/site/external"
    : "https://dev-cdn.uvenu.com/site/external";
export let CDN_URLS = {
  UVENU_LOADING: `${CDN_URL}/Uvenu_Loading.gif`,
  MOBILE_FRAME: `${CDN_URL}/mobile_frame.png`,
  BRIGHTNESS: `${CDN_URL}/increase_brightness.webm`,
  SHADOW_BACKGROUND: `${CDN_URL}/uvenu_background_shade.png`,
  AVIATORS_EFFECT: `${CDN_URL}/DeepAR/aviators.deepar`,
  FIRE_EFFECT: `${CDN_URL}/DeepAR/fireEffect.deepar`,
  JAGS_FACEPAINT_EFFECT: `${CDN_URL}/DeepAR/JAGS_FACEPAINT_V1.deepar`,
  NFL_HELMET_EFFECT: `${CDN_URL}/DeepAR/nfl-helmet-red.deepar`,
  EVENT_JSON_DATA_LINK: (eventId) =>
    `${CDN_URL}/events/${eventId}/sharedMedia/files.json`,
  FFMPEG_CORE: `${CDN_URL}/ffmpeg/v1/ffmpeg-core.js`,
  FFMPEG_WASM: `${CDN_URL}/ffmpeg/v1/ffmpeg-core.wasm`,
  FFMPEG_CORE_WORKER: `${CDN_URL}/ffmpeg/v1/ffmpeg-core.worker.js`,
};

export function updateCDNUrls(cdnUrl = CDN_URL) {
  CDN_URL = cdnUrl;
  CDN_URLS = {
    UVENU_LOADING: `${CDN_URL}/Uvenu_Loading.gif`,
    MOBILE_FRAME: `${CDN_URL}/mobile_frame.png`,
    BRIGHTNESS: `${CDN_URL}/increase_brightness.webm`,
    SHADOW_BACKGROUND: `${CDN_URL}/uvenu_background_shade.png`,
    AVIATORS_EFFECT: `${CDN_URL}/DeepAR/aviators.deepar`,
    FIRE_EFFECT: `${CDN_URL}/DeepAR/fireEffect.deepar`,
    JAGS_FACEPAINT_EFFECT: `${CDN_URL}/DeepAR/JAGS_FACEPAINT_V1.deepar`,
    NFL_HELMET_EFFECT: `${CDN_URL}/DeepAR/nfl-helmet-red.deepar`,
    EVENT_JSON_DATA_LINK: (eventId) =>
      `${CDN_URL.replace(
        "/site/external",
        ""
      )}/events/${eventId}/sharedMedia/files.json`,
    FFMPEG_CORE: `${CDN_URL}/ffmpeg/v1/ffmpeg-core.js`,
    FFMPEG_WASM: `${CDN_URL}/ffmpeg/v1/ffmpeg-core.wasm`,
    FFMPEG_CORE_WORKER: `${CDN_URL}/ffmpeg/v1/ffmpeg-core.worker.js`,
  };
}

export let APP_INSIGHTS_CONNECTION_STRING = "InstrumentationKey=e1b01d13-dd48-4dad-a69c-4dfe0e1ef970;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/";
export function updateAppInsightsConnectionString(connectionString) {
  APP_INSIGHTS_CONNECTION_STRING = connectionString;
}

export const ENABLE_WEBRTC_DEBUG_LOGGING = true;

export const WEB_PUBSUB_SOCKETIO_ENDPOINT = "https://dev-w-uvenu-socketio.webpubsub.azure.com"
export const WEB_PUBSUB_CLIENT_PATH = "/clients/socketio/hubs/Hub"





